import * as d3 from 'd3';
import cx from 'classnames';
import { Dispatch } from 'react';
import { Moon } from 'lunarphase-js';

import { THEME } from '../../../../config/commons';
import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { defaultGrayColor, getNumberTicks } from '../../../../pages/OptimalHarvestPoint/helpers';
import * as optimalHarvestPointSlice from '../../../../pages/OptimalHarvestPoint/optimalHarvestPointSlice';
import { CommercialSizeData, IPoint, PocByPacker, PredictionPoint, ProfitType } from '../../../../pages/OptimalHarvestPoint/interfaces';

import './OptimalHarvestPointChartD3.scss';
import styles from './OptimalHarvestPointChartD3.module.scss';
import { calculateTooltipBottom, calculateTooltipExtraPaddingLeft, generateLines, getChartLeftPosition, getCommercialSizeData, getCommercialSizeDataPoints, getPresentValue, getYDomainData, getYValue, renderTickFormat } from './helpers';

let currentStageActive: string;
let predictionSelected: CommercialSizeData | undefined = undefined;
let selectedPoint: IPoint;
let numbersTicks: number;

const TICK_PADDING_BOTTOM_AXIS = 5;
const TICK_PADDING_LEFT_AXIS = 4;
const DEFAULT_POINT_SIZE = 3;
const POINT_ACTIVE_SIZE = DEFAULT_POINT_SIZE + 3;
const BEST_POINT_SIZE = POINT_ACTIVE_SIZE + 3;
const SELECTED_POINT_SIZE = BEST_POINT_SIZE;
const TIME_TRANSITION = 300;
const TICKS_NUMBER_Y = 8;
const widthSelectedRect = POINT_ACTIVE_SIZE + 28;

interface Props {
  companyData: Company;
  container: HTMLDivElement | null;
  pocPoint?: CommercialSizeData;
  bestPackers: PocByPacker[];
  allPredictions: PredictionPoint[];
  chartParameter: string;
  firstStage: number;
  height: number;
  lastStage: number;
  width: number;
  selectedTickStroke?: string;
  dispatch?: Dispatch<GenericParam>;
  initialPopulation: number;
  currencySymbol: string;
  profitType: ProfitType;
  showTriangle: boolean;
}

interface RefreshProps {
  width: number;
  height: number;
  lastStage: number;
  firstStage: number;
  chartParameter: string;
  bestPackers: PocByPacker[];
  pocPoint?: CommercialSizeData;
  allPredictions: PredictionPoint[];
  initialPopulation: number;
  selectedTickStroke: string;
  dispatch: Dispatch<GenericParam>;
  currencySymbol: string;
  companyData: Company;
  profitType: ProfitType;
  showTriangle: boolean;
}

class OptimalHarvestPointChartD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, IPoint, null, undefined>;
  groupMain: d3.Selection<SVGGElement, IPoint, null, undefined>;
  containerOptimalHarvestBar: d3.Selection<SVGGElement, IPoint, null, undefined>;
  optimalHarvestPointBar: d3.Selection<SVGRectElement, IPoint, null, undefined> = d3.select<SVGRectElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));

  scaleLinearX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  margin = { top: 32, right: 20, bottom: 20, left: 72 };
  yAxisLeft: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  bestPackers: PocByPacker[] = [];
  pocPoint?: CommercialSizeData;
  allPredictions: PredictionPoint[] = [];
  commercialSizeDataPoints : CommercialSizeData[] = [];

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  companyData: Company;

  selectedTickStroke: string;
  chartParameter: string;
  profitType: ProfitType;
  initialPopulation: number;
  currencySymbol: string;
  showTriangle = true;

  dispatch?: Dispatch<GenericParam>;

  // eslint-disable-next-line
  constructor(props: Props) {
    const {
      allPredictions, currencySymbol, pocPoint, bestPackers,
      chartParameter, initialPopulation,
      companyData, container, firstStage, height, lastStage,
      width, selectedTickStroke = 'white', dispatch,
      profitType,
      showTriangle,
    } = props;

    this.container = container;
    this.bestPackers = bestPackers;
    this.pocPoint = pocPoint;
    this.selectedTickStroke = selectedTickStroke;
    this.initialPopulation = initialPopulation;
    this.chartParameter = chartParameter;
    this.allPredictions = allPredictions;
    this.currencySymbol = currencySymbol;
    this.companyData = companyData;
    this.profitType = profitType;
    this.showTriangle = showTriangle;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.dispatch = dispatch;
    predictionSelected = undefined;

    d3.select(container).select('#tooltipChart').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select<HTMLDivElement | null, IPoint>(container)
      .append('svg')
      .attr('id', 'svgPOC')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.containerOptimalHarvestBar = this.svg.append('g')
      .attr('id', 'groupOptimalHarvestBar')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top / 2})`);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'contentPOC')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top / 1.2})`)
      .style('pointer-events', 'all');

    this.firstStage = firstStage;
    this.lastStage = lastStage;

    this.commercialSizeDataPoints = getCommercialSizeDataPoints({ allPredictions, bestPackers });

    this.createDataPoints();
    this.renderTooltips();
  }

  renderLineSelected () {
    const { container, groupMain, height, selectedTickStroke } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStroke)
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipChart')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select(this.container).select('#selectedTick').style('display', 'block');
        d3.select(this.container).selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);
      });
  }

  createDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.renderLinesAxis();
    this.buildAxisX();
    this.buildAxisY();
    
    this.createTooltip();
    
    this.renderLineSelected();
    this.renderLines();
    
    this.drawXAxis();
    this.drawYAxis();
    this.renderPhaseLunar();
    this.renderPoints();
    this.renderPointText({ point: this.pocPoint, id: 'optimalHarvestPoint' });
    this.createOptimalHarvestBar();

    setTimeout(() => {
      this.showOrHideFirstLineOfAxisXTick();
    }, 400);
  };

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', 'lineAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderLeftAxisTriangle () {
    const { container, margin, showTriangle } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: 0,
    };

    d3.select(container).selectAll('.triangle').remove();

    if (!showTriangle) {
      return;
    }

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);
  }

  isLightTheme = () => getCurrentTheme() === THEME.LIGHT;

  updateAxis () {
    const { height, firstStage, lastStage } = this;

    this.renderLeftAxisTriangle();
    this.renderLinesAxis();
    
    this.buildAxisX();
    this.buildAxisY();

    numbersTicks = getNumberTicks({ firstStage, lastStage });

    const axisBottom = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING_BOTTOM_AXIS);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .attr('class', cx(styles.axisX, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    const axisLeft = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING_LEFT_AXIS);

    this.yAxisLeft
      .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    setTimeout(() => {
      this.showOrHideFirstLineOfAxisXTick();
    }, 400);
  }

  updateDataPoints () {
    this.updateAxis();

    this.renderLines();
    
    this.renderPhaseLunar();
    this.renderPoints();
    this.renderPointText({ point: this.pocPoint, id: 'optimalHarvestPoint' });
    this.updateOptimalHarvestBar();
  }

  refreshChart (props: RefreshProps) {
    const {
      allPredictions, currencySymbol, companyData, pocPoint, bestPackers,
      chartParameter, initialPopulation, dispatch,
      firstStage, lastStage, width, height,
      selectedTickStroke,
      profitType,
      showTriangle,
    } = props;
    const { tooltip, container } = this;

    this.pocPoint = pocPoint;
    this.allPredictions = allPredictions;
    this.bestPackers = bestPackers;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.selectedTickStroke = selectedTickStroke;
    this.initialPopulation = initialPopulation;
    this.dispatch = dispatch;
    this.chartParameter = chartParameter;
    this.currencySymbol = currencySymbol;
    this.companyData = companyData;
    this.profitType = profitType;
    this.showTriangle = showTriangle;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();

    tooltip.style('display', 'none');

    this.commercialSizeDataPoints = getCommercialSizeDataPoints({ allPredictions, bestPackers });
    this.updateSize(width, height);
    this.renderLineSelected();
    this.updateDataPoints();
    this.renderTooltips();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    const minX = firstStage;
    const maxX = lastStage;

    this.scaleLinearX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  }

  buildAxisY () {
    const { height, allPredictions, chartParameter, bestPackers, profitType } = this;
    const { maxY, minY } = getYDomainData({ allPredictions, chartParameter, bestPackers, profitType });

    this.scaleLinearY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  }

  showOrHideFirstLineOfAxisXTick () {
    const { container } = this;
    const tickG = d3.select(container).selectAll<HTMLElement, undefined>('#axisX>.tick').nodes()[0];
    const transformProperty: string = tickG?.getAttribute('transform') || '';
    const match = transformProperty.match(/translate\((-?\d+(\.\d+)?),/);
    
    if (match) {
      const xPosition = Math.floor(parseFloat(match[1]));

      if (xPosition === 0) {
        d3.select(tickG).select('line').style('display', 'none');
      } else {
        d3.select(tickG).select('line').style('display', 'flex');
      }
    } else {
      d3.select(tickG).select('line').style('display', 'flex');
    }
  }

  renderTooltips () {
    const { scaleLinearX, scaleLinearY, tooltip,
      firstStage, lastStage,
      width, height,
      groupMain,
      renderTooltipsContent,
      allPredictions,
      commercialSizeDataPoints,
      onClickCircle,
      bestPackers,
      profitType,
      pocPoint,
      currencySymbol,
      container,
    } = this;

    const bisect = d3.bisector(function (point: IPoint) {
      return point.x;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseleave', function () {
        tooltip.style('display', 'none');
        d3.select(container).select('#selectedTick').style('display', 'none');
        d3.select(container).selectAll(currentStageActive).attr('r', DEFAULT_POINT_SIZE);
      })
      .on('mousemove', function (event) {
        let x0 = scaleLinearX.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= scaleLinearX.domain()[0]) && (x0 <= scaleLinearX.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const index = bisect(allPredictions, x0, 1);
        
        const previousPoint = allPredictions[index - 1];
        const currentPoint = allPredictions[index];

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.x < firstStage || selectedPoint.x > lastStage)) {
          tooltip.style('display', 'block');
          d3.select(container).select('#selectedTick').style('display', 'block');
        }

        const dataByStage = allPredictions.find(item => item.x === selectedPoint?.x) as PredictionPoint;
        if (!dataByStage) {
          return;
        }

        const pointsList: IPoint[] = commercialSizeDataPoints.filter(item => item.x === dataByStage.x) ;

        d3.selectAll('.points circle').attr('r', DEFAULT_POINT_SIZE);
        d3.selectAll('.points .best-point').attr('r', BEST_POINT_SIZE);
        d3.selectAll('.points .selected-point').attr('r', SELECTED_POINT_SIZE);

        if (pointsList?.length === 0) {
          tooltip.style('display', 'none');
          d3.select(container).select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: IPoint = pointsList.reduce(function (prev: IPoint, current: IPoint) {
          if (profitType === ProfitType.PROFIT_PER_DAY) {
            return ((prev as CommercialSizeData).presentValue.potentialGainByDayHectarea > (current as CommercialSizeData).presentValue.potentialGainByDayHectarea) ? prev : current;
          }

          return ((prev as CommercialSizeData).presentValue.potentialGain > (current as CommercialSizeData).presentValue.potentialGain) ? prev : current;
        });

        const lowestValue: IPoint = pointsList.reduce(function (prev: IPoint, current: IPoint) {
          if (profitType === ProfitType.PROFIT_TOTAL) {
            return ((prev as CommercialSizeData).presentValue.potentialGainByDayHectarea < (current as CommercialSizeData).presentValue.potentialGainByDayHectarea) ? prev : current;
          }

          return ((prev as CommercialSizeData).presentValue.potentialGain < (current as CommercialSizeData).presentValue.potentialGain) ? prev : current;
        });

        const marginLeft = scaleLinearX(higherValue.x);
        const potentialGain = profitType === ProfitType.PROFIT_PER_DAY ? (lowestValue as CommercialSizeData).presentValue.potentialGainByDayHectarea : (lowestValue as CommercialSizeData).presentValue.potentialGain;
        const marginBottom = scaleLinearY(potentialGain);

        currentStageActive = `.stage${higherValue.x}`;
        d3.selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);

        const tooltipDialogWidth = 176;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidthDefault = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidthDefault < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width, tooltipLeftAdjustmentRatio: 2.10 };

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', calculateTooltipExtraPaddingLeft({ marginLeft, width, tooltip }));

        tooltip
          .style('left', getChartLeftPosition(leftPositionProps))
          .style('bottom', calculateTooltipBottom({ marginBottom, height, tooltip }));

        d3.select(container).select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsContent({ allPredictions, bestPackers, pointsList: pointsList as CommercialSizeData[], tooltipContent, onClickCircle, pointX: x0, pocPoint, currencySymbol });
      });
  }

  renderTooltipsContent (props: {allPredictions: PredictionPoint[]; bestPackers: PocByPacker[]; pointsList: CommercialSizeData[]; tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>; onClickCircle: Function; pointX: number; pocPoint?: CommercialSizeData; currencySymbol: string; }) {
    const { allPredictions, bestPackers, pointsList, tooltipContent, onClickCircle, pointX, pocPoint, currencySymbol } = props;

    const entry = tooltipContent
      .append('div')
      .attr('class', styles.entry);
    
    const entryTitle = entry.append('div')
      .attr('class', styles.entryHeader);

    entryTitle.append('div')
      .attr('class', styles.entryTitle)
      .html('Selecciona una empacadora');
    
    const radioGroup = entry.append('div').attr('class', styles.radioGroup);

    const predictionPoints: CommercialSizeData[] = getCommercialSizeData({ allPredictions, packerId: predictionSelected?.packerId });
    const predictionPointByStage = predictionPoints.find((point) => point.x === predictionSelected?.x);

    for (let index = 0; index < bestPackers.length; index++) {
      const bestPacker = bestPackers[index];

      const radioEntry = radioGroup.append('div').attr('class', styles.radioEntry);
      const radioLabel = radioEntry.append('label').attr('class', styles.radioLabel);

      const isSelectedPocPoint = pocPoint?.packerName === bestPacker?.packerName && pointX === bestPacker?.x;
      const isChecked = predictionSelected?.packerName === bestPacker?.packerName && pointX === predictionPointByStage?.x;

      radioLabel.append('input')
        .attr('type', 'radio')
        .attr('name', 'packerSelection')
        .attr('value', bestPacker.packerName)
        .attr('id', `radio-${index}`)
        .attr('class', styles.radioInput)
        .property('checked', isChecked)
        .on('change', () => {
          const point = pointsList.find((point) => point.packerId === bestPacker.packerId);
          onClickCircle({ point });
        });

      radioLabel.append('div')
        .attr('class', styles.radioCustom)
        .style('background-color', bestPacker.color);

      radioLabel.append('label')
        .attr('for', `radio-${index}`)
        .attr('id', `label-${bestPacker?.packerId}-x-${pointX}`)
        .attr('class', cx(styles.radioText, isChecked ? styles.selectedPacker : '', 'radioText'))
        .html(`${bestPacker.packerName}`);

      if (isSelectedPocPoint) {
        radioLabel.append('div')
          .attr('class', styles.selectedPocPoint)
          .style('border-color', bestPacker.color)
          .style('color', bestPacker.color)
          .html(currencySymbol);
      }
    }
  }

  renderLines () {
    const { container, groupMain, bestPackers, commercialSizeDataPoints, scaleLinearX, scaleLinearY, chartParameter, profitType } = this;

    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY: scaleLinearY, chartParameter, profitType });
    d3.select(container).selectAll('.pathLine').remove();

    for (let index = 0; index < bestPackers.length; index++) {
      const packer = bestPackers[index];
      const points = commercialSizeDataPoints.filter(item => item.packerId === packer.packerId);

      /* eslint-disable @typescript-eslint/no-explicit-any */
      groupMain
        .append('path')
        .datum(points as any)
        .attr('class', 'pathLine')
        .attr('stroke', packer.color)
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', index === 0 ? 5.5 : 0)
        .attr('d', lineCurve);
    }
  }

  drawXAxis () {
    const { height, groupMain, firstStage, lastStage } = this;

    numbersTicks = getNumberTicks({ firstStage, lastStage });

    const axis = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING_BOTTOM_AXIS);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);
  }

  drawYAxis () {
    const { groupMain } = this;

    const axis = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING_LEFT_AXIS);

    this.yAxisLeft = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  getDefaultColor () {
    return this.isLightTheme() ? '#a8b7ec' : '#d9d9d9';
  }

  isBestPacker = (point: IPoint) => {
    const { bestPackers, pocPoint } = this;
    return pocPoint?.x === point.x && 'packerId' in point && bestPackers.findIndex(item => item.packerId === point.packerId) === 0;
  };

  getCircleClassName = (point: IPoint) => {
    if (this.isBestPacker(point)) {
      return 'best-point';
    }

    if (predictionSelected?.x === point.x && 'packerId' in point && predictionSelected?.packerId === point.packerId) {
      return 'selected-point';
    }

    if (this.firstStage <= point.x && point.x <= this.lastStage) {
      return cx(styles.circle, `stage${point.x}`);
    }

    return styles.hideCircle;
  };

  getCircleRadius = (point: IPoint) => {
    if (this.isBestPacker(point)) {
      return BEST_POINT_SIZE;
    }

    if (predictionSelected?.x === point.x && 'packerId' in point && predictionSelected?.packerId === point.packerId) {
      return SELECTED_POINT_SIZE;
    }

    if (predictionSelected?.x === point.x) {
      return POINT_ACTIVE_SIZE;
    }

    return DEFAULT_POINT_SIZE;
  };

  fillPoints (point: IPoint) {
    const defaultFillColor = this.getDefaultColor();

    if ('_id' in point) {
      return defaultFillColor;
    }

    if (this.isBestPacker(point)) {
      return 'white';
    }

    if (predictionSelected?.x === point.x && 'packerId' in point && predictionSelected?.packerId === point.packerId) {
      return 'white';
    }

    if ('packerId' in point) {
      return point.color;
    }

    return '#d2c15f';
  }

  fillStroke (point: IPoint) {
    if ('packerId' in point && this.isBestPacker(point)) {
      return point.color;
    }

    if (predictionSelected?.x === point.x && 'packerId' in point && predictionSelected?.packerId === point.packerId) {
      return point.color;
    }

    return this.fillPoints(point);
  }

  onClickCircle = (params: { point: IPoint }) => {
    const { point } = params;

    if ('packerId' in point && this?.dispatch) {
      predictionSelected = point as CommercialSizeData;
      selectedPoint = {} as IPoint;
      this.dispatch(optimalHarvestPointSlice.setPredictionSelected(point));
    }
  };

  createOptimalHarvestBar = () => {
    const { container, containerOptimalHarvestBar, height, pocPoint, margin } = this;

    d3.select(container).select('#optimalHarvestBar').remove();
    
    const pointX = pocPoint?.x || 0;
    const positionX = (pointX) === 0 ? 0 : this.scaleLinearX(pointX) - (widthSelectedRect / 2);
    const fill = this.isLightTheme() ? '#D9FFDD' : '#D9FFDD80';

    this.optimalHarvestPointBar = containerOptimalHarvestBar.append('rect')
      .attr('id', 'optimalHarvestBar')
      .attr('fill', fill)
      .attr('stroke', '#74C484')
      .attr('x', positionX)
      .attr('y', - 8)
      .attr('width', widthSelectedRect)
      .attr('height', height + margin.top + 5)
      .attr('rx', 18)
      .attr('ry', 18);
  };

  updatePositionXOptimalHarvestBar = (props: { x: number; y?: number; fill: string; stroke: string; color?: string; }) => {
    const { height, margin } = this;
    const { x, y, fill, stroke, color } = props;
    const bar = this.svg.select('#optimalHarvestBar');

    this.svg
      .select('#optimalHarvestBar')
      .transition()
      .duration(TIME_TRANSITION)
      .attrTween('fill', () => d3.interpolateRgb(bar.attr('fill'), fill))
      .attrTween('stroke', () => d3.interpolateRgb(bar.attr('stroke'), stroke))
      .attr('x', this.scaleLinearX(x) - (widthSelectedRect / 2))
      .attr('height', height + margin.top + 5)
      .on('end', () => {
        if (!y || !color) {
          return;
        }

        this.createMovingDots({ xPosition: x, yPosition: y, color });
      });
  };

  createMovingDots = (props: {xPosition: number; yPosition: number; color?: string; }) => {
    const { xPosition, yPosition, color = '#74C484' } = props;

    const { groupMain, scaleLinearY, scaleLinearX } = this;
    const numDots = 48;
    const dotsContainer = groupMain.append('g').attr('class', 'dots-container');

    for (let i = 0; i < numDots; i++) {
      const angle = Math.random() * 360;
      const startX = scaleLinearX(xPosition);
      const startY = scaleLinearY(yPosition);
      const distance = (Math.random() * 30) + 20;

      dotsContainer.append('circle')
        .attr('cx', startX)
        .attr('cy', startY)
        .attr('r', 3)
        .attr('fill', color)
        .attr('opacity', 1)
        .transition()
        .duration(600)
        .ease(d3.easeCircleOut)
        .attr('cx', startX + (distance * Math.cos(angle)))
        .attr('cy', startY + (distance * Math.sin(angle)))
        .attr('opacity', 0)
        .on('end', function () {
          d3.select(this).remove();
        });
    }
  };

  updateOptimalHarvestBar = () => {
    const { pocPoint, profitType } = this;

    if (!pocPoint?.x) {
      return;
    }

    if (!predictionSelected?.x || predictionSelected?.x === pocPoint?.x) {
      const presentValue = getPresentValue({ point: pocPoint, profitType });
      const y = predictionSelected?.packerId === pocPoint?.packerId ? presentValue : undefined;
      const color = predictionSelected?.color === defaultGrayColor ? undefined : predictionSelected?.color;
      const fill = this.isLightTheme() ? '#D9FFDD' : '#D9FFDD33';

      this.updatePositionXOptimalHarvestBar({ x: pocPoint.x, y, color, fill, stroke: '#74C484' });
      return;
    }

    if (this.isLightTheme()) {
      this.updatePositionXOptimalHarvestBar({ x: predictionSelected.x, fill: '#F6F6F6', stroke: '#DADADA' });
      return;
    }

    this.updatePositionXOptimalHarvestBar({ x: predictionSelected.x, fill: '#050a30', stroke: '#DADADA' });
  };

  shouldIncludePoint = (point: CommercialSizeData) => {
    const { pocPoint } = this;

    const sameXAsPoc = pocPoint?.x === point.x;
    const sameXAsPrediction = predictionSelected?.x === point.x;
    const samePackerAsPoc = pocPoint?.packerId === point.packerId;
    const samePackerAsPrediction = predictionSelected?.packerId === point.packerId;
    const samePotentialGainAsPoc = pocPoint?.netValue.potentialGain === point.netValue.potentialGain;
    const samePotentialGainAsPrediction = predictionSelected?.netValue.potentialGain === point.netValue.potentialGain;
  
    if (sameXAsPoc && sameXAsPrediction) {
      return samePackerAsPoc || samePackerAsPrediction || !(samePotentialGainAsPoc || samePotentialGainAsPrediction);
    }
  
    if (sameXAsPoc) {
      return samePackerAsPoc || !samePotentialGainAsPoc;
    }
  
    if (sameXAsPrediction) {
      return samePackerAsPrediction || !samePotentialGainAsPrediction;
    }
  
    return true;
  };

  renderPoints = () => {
    const { container, scaleLinearX, scaleLinearY, groupMain, commercialSizeDataPoints, chartParameter, profitType } = this;

    d3.select(container).selectAll('.points').remove();

    const gPoints = groupMain.append('g')
      .attr('class', 'points')
      .attr('fill', 'transparent')
      .attr('cursor', 'default');

    gPoints
      .selectAll('circle')
      .data(commercialSizeDataPoints)
      .enter()
      .append('circle')
      .filter(this.shouldIncludePoint)
      .attr('class', (point) => this.getCircleClassName(point))
      .attr('r', (point) => this.getCircleRadius(point))
      .attr('cx', (point) => scaleLinearX(point.x))
      .attr('cy', (point) => scaleLinearY(getYValue({ point, chartParameter, profitType })))
      .attr('fill', (point) => this.fillPoints(point))
      .attr('stroke', (point) => this.fillStroke(point))
      .attr('stroke-width', 1);

    gPoints.selectAll('text')
      .data(commercialSizeDataPoints)
      .enter()
      .append('text')
      .filter((point) => this.isBestPacker(point))
      .attr('class', styles.currencySymbol)
      .attr('x', (point) => scaleLinearX(point.x))
      .attr('y', (point) => scaleLinearY(getYValue({ point, chartParameter, profitType })) + 4)
      .attr('dy', '0.05em')
      .style('fill', (point) => this.fillStroke(point))
      .text(this.currencySymbol);
  };

  renderPhaseLunar = () => {
    const { container, scaleLinearX, groupMain, commercialSizeDataPoints, bestPackers, pocPoint } = this;
    const commercialSizeDataPointsFiltered = commercialSizeDataPoints.filter((point) => point.packerId === bestPackers[0].packerId);

    d3.select(container).selectAll('.phaseLunar').remove();

    const gPoints = groupMain.append('g')
      .attr('class', 'phaseLunar')
      .attr('fill', 'transparent')
      .attr('cursor', 'default');

    const ticks = this.scaleLinearX.ticks();
    if (pocPoint) {
      if (predictionSelected) {
        ticks.push(predictionSelected.x);
      } else {
        ticks.push(pocPoint.x);
      }
    }
    
    gPoints.selectAll('text')
      .data(commercialSizeDataPointsFiltered)
      .enter()
      .append('text')
      .attr('class', styles.moon)
      .attr('x', (point) => scaleLinearX(point.x))
      .attr('y', 4)
      .style('font-size', '14px')
      .style('text-anchor', 'middle')
      .style('fill', 'black')
      .text((point) => {
        if (!point.predictionDate || !ticks.includes(point.x)) {
          return '';
        }

        const emoji = Moon.lunarPhaseEmoji(new Date(point.predictionDate));
        return emoji;
      });
  };

  renderPointText = (props: {point: CommercialSizeData | undefined; id: string; }) => {
    const { point, id } = props;

    const { container, height, groupMain } = this;
    
    const ticks = this.scaleLinearX.ticks();
    d3.select(container).select(`#${id}`).remove();

    if (!point?.x) {
      return;
    }
    
    if (ticks.includes(point.x)) {
      return;
    }

    groupMain.append('text')
      .attr('id', id)
      .attr('class', cx(styles.axisXValue, this.isLightTheme() ? styles.axisXValueLight : styles.axisXValueDark))
      .attr('x', this.scaleLinearX(point.x))
      .attr('y', height + TICK_PADDING_BOTTOM_AXIS)
      .attr('dy', '0.71em')
      .style('text-anchor', 'middle')
      .text(point.x);
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };

  refreshPoints = (props: { predictionSelected: CommercialSizeData | undefined; }) => {
    predictionSelected = props.predictionSelected;

    this.renderPhaseLunar();
    this.renderPoints();
    this.updateOptimalHarvestBar();

    this.renderPointText({ point: predictionSelected, id: 'predictionPoint' });

    d3.select(this.container).selectAll('.radioText')
      .attr('class', cx(styles.radioText, 'radioText'));

    const id = `#label-${predictionSelected?.packerId}-x-${predictionSelected?.x}`;
    d3.select(this.container).select(id)
      .attr('class', cx(styles.radioText, styles.selectedPacker, 'radioText'));
  };
}

export default OptimalHarvestPointChartD3;
