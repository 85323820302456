import moment from 'moment';
import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../utils/sort';
import { Analysis } from '../Analysis/interfaces';
import { uploadFileToS3 } from '../../utils/upload_s3';
import { getUserSession } from '../../utils/userSession';
import { PresignedPostURL } from '../Payments/interfaces';
import { UserSession } from '../../common/interfaces/auth';
import { generateExcel } from '../../helpers/biometrics.helpers';
import { axiosClient as axios } from '../../utils/axios_instance';
import { Maturation, MaturationCode } from '../Clients/interfaces';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { getUnitPhaseTypeFromStocking, showErrorStocking } from '../../helpers/stocking.helpers';
import { openErrorNotification, openSuccessNotification } from '../../common/notification/Notification';
import { STOCKINGS_URL, TANKS_URL, MODULES_URL, CAMPUS_URL, MATURATIONS_URL, XLSX_ANALYSIS_REPORT_URL, GENERATE_PRESIGNED_URL, STOCKING_DATE_RANGES_URL, s3BucketURL, ENVIRONMENT, STOCKING_STAGE_OPTIONS_URL, REFERENCE_CURVES_URL, ANALYSES_URL, XLSX_BIOMETRICS_REPORT_URL, XLSX_PARAMETERS_REPORT_URL } from '../../config/config.api';
import { paginationStockings, typeFetchCampus, typeFetchModule, tankStatuses, stockingPhaseTypes, typeExtension, typeReport, unitStatuses, setImageInvoiceKey, convertDataURLtoFile, bucketName, methodPresignedUrl, stockingsShow, stockingCurrentTab, stockingStatuses, stockingSearchTypes, transferTypes, hasAdminRole, analysisStatuses, xlsxReportTabs, typeBiometricsReport } from '../../config/commons';
import { GenericParam } from '../../common/interfaces/commons';
import { exportToExcel, XlsxExportData } from '../../utils/xlsxReport';
import { getParameterLabelByKey } from '../Company/StockingParameters/helpers';

import { getSortType } from './sowings.helpers';
import { StockingsState, Stocking, StockingPayload, Campus, StockingDateRange, STOCKINGS_SORT, SORT_TYPE, FetchStockingParams, CreateStockingProps, PropsStockingByBindingCode, UpdateReferralGuideProps, EditStockingProps, StockingStageOptions, CreateStockingsProps, Container, Module, ReferenceCurve, BiometricData } from './interfaces';

export const lastDaysXlsxReport = 60;

const initialState: StockingsState = {
  campusesForm: [],
  modulesForm: [],
  tanksForm: [],
  campusesMove: [],
  modulesMove: [],
  tanksMove: [],
  referenceCurves: [],
  filters: {
    campuses: [],
    modules: [],
    tanks: [],
    maturations: [],
    maturationCodes: [],
    stockingsToShow: stockingsShow.ACTIVE,
    searchValue: '',
    showSearchInput: false,
    searchType: stockingSearchTypes.NAME,
    sorts: {
      byCampusName: SORT_TYPE.DEFAULT,
      byModuleName: SORT_TYPE.DEFAULT,
      byTankName: SORT_TYPE.ASC,
      byStockingDate: SORT_TYPE.DEFAULT,
    },
  },
  maturations: [],
  maturationCodes: [],
  list: [],
  total: 0,
  skip: 0,
  limit: paginationStockings,
  showModalEdit: false,
  showModalMove: false,
  currentPage: 0,
  isLoading: false,
  isLoadingEdit: false,
  isLoadingCreate: false,
  hasErrorEdit: false,
  isLoadingStocking: false,
  isLoadingLastAnalysis: false,
  isLoadingFinish: false,
  isLoadingToMove: false,
  existErrorToMove: false,
  selectedStocking: {
    _id: '',
    code: '',
    type: '',
    bindingStockings: [],
    populations: [],
    name: '',
    status: '',
    startDate: '',
    startDateJuvenile: '',
    startDateGrowOut: '',
    endDate: '',
    naupliusNumber: 0,
    juvenilesNumber: 0,
    growOutNumber: 0,
    weight: 0,
    litersNumber: 0,
    cubicMeters: 0,
    hectares: 0,
    createdAt: '',
    comment: '',
    stage: 0,
    shouldFinish: false,
    poundsHarvested: 0,
    harvestQuantity: 0,
    phaseType: '',
    companyId: '',
    larvaePerGram: 0,
    campusId: {
      _id: '',
      name: '',
      code: '',
      status: '',
      phaseType: '',
    },
    tankId: {
      _id: '',
      name: '',
      status: '',
      type: ''
    },
    maturationId: {
      _id: '',
      name: '',
      codes: [],
    },
    maturationCode: '',
    moduleId: {
      _id: '',
      name: '',
    },
    analysisCount: 0,
    isPublic: false,
    isArchived: false,
    transfers: [],
    harvests: [],
    customStage: {
      key: '',
      value: 0,
    },
    daysToInitialStage: 0,
    labNames: [],
  },
  selectedCampus: {
    _id: '',
    name: '',
    code: '',
    status: '',
    province: '',
    phaseType: '',
  },
  lastAnalysis: undefined,
  selectedModuleId: undefined,
  selectedTankId: undefined,
  selectedMaturationId: undefined,
  selectedMaturationCodeId: undefined,
  xlsxReport: {
    fromDate: moment(new Date()).subtract(lastDaysXlsxReport - 1, 'days').toString(),
    toDate: moment(new Date()).toString(),
    data: {},
    showModal: false,
    isLoading: false,
    typeFile: typeExtension.XLSX,
    typeReportCsv: typeReport.ANALYSIS,
    tabSelected: xlsxReportTabs.DATABASE,
    biometricsDate: moment(new Date()).toString(),
    parametersDate: moment(new Date()).toString(),
    typeBiometrics: typeBiometricsReport.WEIGHT_GROUP,
    unit: '',
    parametersUnit: '',
  },
  publishLarviaTrade: {
    showModal: false,
    animalsOffered: 0,
    isPublic: false,
    isLoadingToPublish: false,
  },
  stockingDateRanges: {
    larvae: {
      startDateLarvae: {
        max: 0,
        min: 0,
      }
    },
    juvenile: {
      startDateLarvae: {
        max: 0,
        min: 0,
      },
      startDateJuvenile: {
        max: 0,
        min: 0,
      }
    },
    growOut: {
      startDateGrowOut: {
        max: 0,
        min: 0,
      }
    }
  },
  currentTabSetting: stockingCurrentTab.EDIT,
  showCreateModalLarvae: false,
  showCreateModalJuvenile: false,
  showCreateModalGrowOut: false,
  stageOptions: {},
};

export const stockingsSlice = createSlice({
  name: 'sowings',
  initialState,
  reducers: {
    setStockings: (state: StockingsState, action: PayloadAction<StockingPayload>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.list = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },

    setIsLoading: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setStocking: (state: StockingsState, action: PayloadAction<Stocking>) => {
      state.selectedStocking = action.payload;
    },

    setLastAnalysis: (state: StockingsState, action: PayloadAction<Analysis | undefined>) => {
      state.lastAnalysis = action.payload;
    },

    setCampusesForm: (state: StockingsState, action: PayloadAction<Campus[]>) => {
      state.campusesForm = action.payload;
    },
    setModulesForm: (state: StockingsState, action: PayloadAction<Module[]>) => {
      state.modulesForm = action.payload;
    },
    setTanksForm: (state: StockingsState, action: PayloadAction<Container[]>) => {
      state.tanksForm = action.payload;
    },

    setCampusesMove: (state: StockingsState, action: PayloadAction<Campus[]>) => {
      state.campusesMove = action.payload;
    },
    setModulesMove: (state: StockingsState, action: PayloadAction<Module[]>) => {
      state.modulesMove = action.payload;
    },
    setTanksMove: (state: StockingsState, action: PayloadAction<Container[]>) => {
      state.tanksMove = action.payload;
    },
    setReferenceCurves: (state: StockingsState, action: PayloadAction<ReferenceCurve[]>) => {
      state.referenceCurves = action.payload;
    },

    setCampusesFilter: (state: StockingsState, action: PayloadAction<Campus[]>) => {
      state.filters.campuses = action.payload;
    },
    setModulesFilter: (state: StockingsState, action: PayloadAction<Module[]>) => {
      state.filters.modules = action.payload;
    },
    setTanksFilter: (state: StockingsState, action: PayloadAction<Container[]>) => {
      state.filters.tanks = action.payload;
    },
    setMaturationsFilter: (state: StockingsState, action: PayloadAction<Maturation[]>) => {
      state.filters.maturations = action.payload;
    },
    setMaturationCodesFilter: (state: StockingsState, action: PayloadAction<MaturationCode[]>) => {
      state.filters.maturationCodes = action.payload;
    },

    setSearchValue: (state: StockingsState, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setShowSearchInput: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.filters.showSearchInput = action.payload;
    },
    setSearchType: (state: StockingsState, action: PayloadAction<string>) => {
      state.filters.searchType = action.payload;
    },

    setMaturations: (state: StockingsState, action: PayloadAction<Maturation[]>) => {
      state.maturations = action.payload;
    },
    setMaturationCodes: (state: StockingsState, action: PayloadAction<MaturationCode[]>) => {
      state.maturationCodes = action.payload;
    },
    setCampusSelected: (state: StockingsState, action: PayloadAction<Campus | undefined>) => {
      state.selectedCampus = action.payload;
    },
    setModuleSelected: (state: StockingsState, action: PayloadAction<string | undefined>) => {
      state.selectedModuleId = action.payload;
    },
    setTankSelected: (state: StockingsState, action: PayloadAction<string | undefined>) => {
      state.selectedTankId = action.payload;
    },
    setMaturationSelected: (state: StockingsState, action: PayloadAction<string | undefined>) => {
      state.selectedMaturationId = action.payload;
    },
    setMaturationCodeSelected: (state: StockingsState, action: PayloadAction<string | undefined>) => {
      state.selectedMaturationCodeId = action.payload;
    },

    setIsLoadingFinish: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingFinish = action.payload;
    },

    setExistErrorToMove: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.existErrorToMove = action.payload;
    },
    setIsLoadingToMove: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingToMove = action.payload;
    },
    setShowModalEdit: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.showModalEdit = action.payload;
    },
    setShowModalMove: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.showModalMove = action.payload;
    },

    setStockingsToShow: (state: StockingsState, action: PayloadAction<string>) => {
      state.filters.stockingsToShow = action.payload;
    },
    setPage: (state: StockingsState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setHasErrorEdit: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.hasErrorEdit = action.payload;
    },
    setIsLoadingEdit: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingEdit = action.payload;
    },
    setIsLoadingCreate: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingCreate = action.payload;
    },
    setIsLoadingStocking: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingStocking = action.payload;
    },
    setIsLoadingLastAnalysis: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingLastAnalysis = action.payload;
    },
    setXlsxReportFilters: (state: StockingsState, action: PayloadAction<{ fromDate: string; toDate: string }>) => {
      state.xlsxReport.fromDate = action.payload.fromDate;
      state.xlsxReport.toDate = action.payload.toDate;
    },
    setXlsxReportData: (state: StockingsState, action: PayloadAction<{}>) => {
      state.xlsxReport.data = action.payload;
    },
    setShowXlsxReport: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.xlsxReport.showModal = action.payload;
    },
    setIsLoadingXlsxReport: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.xlsxReport.isLoading = action.payload;
    },
    setTypeFile: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.typeFile = action.payload;
    },
    setTypeReportCsv: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.typeReportCsv = action.payload;
    },
    setTabSelected: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.tabSelected = action.payload;
    },
    setUnitSelected: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.unit = action.payload;
    },
    setParametersUnitSelected: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.parametersUnit = action.payload;
    },
    setTypeBiometricsReport: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.typeBiometrics = action.payload;
    },
    setBiometricsDate: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.biometricsDate = action.payload;
    },
    setParametersDate: (state: StockingsState, action: PayloadAction<string>) => {
      state.xlsxReport.parametersDate = action.payload;
    },

    setShowPublishStockingModal: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.publishLarviaTrade.showModal = action.payload;
    },
    setIsLoadingToPublish: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.publishLarviaTrade.isLoadingToPublish = action.payload;
    },

    setStockingDateRanges: (state: StockingsState, action: PayloadAction<StockingDateRange>) => {
      state.stockingDateRanges = action.payload;
    },

    setCurrentTabSetting: (state: StockingsState, action: PayloadAction<string>) => {
      state.currentTabSetting = action.payload;
    },
    setShowCreateModalLarvae: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.showCreateModalLarvae = action.payload;
    },
    setShowCreateModalJuvenile: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.showCreateModalJuvenile = action.payload;
    },
    setShowCreateModalGrowOut: (state: StockingsState, action: PayloadAction<boolean>) => {
      state.showCreateModalGrowOut = action.payload;
    },
    setStageOptions: (state: StockingsState, action: PayloadAction<StockingStageOptions>) => {
      state.stageOptions = action.payload;
    },
    changeSorts: (state: StockingsState, action: PayloadAction<{ sortBy: STOCKINGS_SORT; sortType?: SORT_TYPE }>) => {
      const { sortBy, sortType } = action.payload;
      switch (sortBy) {
        case STOCKINGS_SORT.CAMPUS_NAME:
          state.filters.sorts.byCampusName = sortType ?? getSortType(state.filters.sorts.byCampusName);
          break;
        case STOCKINGS_SORT.MODULE_NAME:
          state.filters.sorts.byModuleName = sortType ?? getSortType(state.filters.sorts.byModuleName);
          break;
        case STOCKINGS_SORT.TANK_NAME:
          state.filters.sorts.byTankName = sortType ?? getSortType(state.filters.sorts.byTankName);
          break;
        case STOCKINGS_SORT.STOCKING_DATE:
          state.filters.sorts.byStockingDate = sortType ?? getSortType(state.filters.sorts.byStockingDate);
          break;
      }
    },
    resetSorts: (state: StockingsState) => {
      state.filters.sorts = {
        byCampusName: SORT_TYPE.DEFAULT,
        byModuleName: SORT_TYPE.DEFAULT,
        byTankName: SORT_TYPE.ASC,
        byStockingDate: SORT_TYPE.DEFAULT,
      };
    },
  },
});

export const {
  setStockings, setStocking, setIsLoading, setModulesForm, setTanksForm,
  setModulesMove, setTanksMove, setLastAnalysis,
  setReferenceCurves,
  changeSorts, resetSorts,
  setCampusesForm, setCampusesFilter, setCampusesMove, setModulesFilter, setTanksFilter,
  setMaturationsFilter, setMaturationCodesFilter,
  setSearchValue, setShowSearchInput, setSearchType,
  setMaturations, setMaturationCodes, setCampusSelected, setModuleSelected, setTankSelected,
  setMaturationSelected, setMaturationCodeSelected,
  setExistErrorToMove, setIsLoadingFinish, setIsLoadingToMove,
  setShowModalEdit, setStockingsToShow, setPage,
  setIsLoadingEdit, setIsLoadingCreate,
  setHasErrorEdit,
  setShowXlsxReport, setIsLoadingXlsxReport, setXlsxReportData, setXlsxReportFilters, setTypeFile, setTypeReportCsv, setTabSelected,
  setShowPublishStockingModal, setIsLoadingToPublish, setStockingDateRanges, setBiometricsDate, setTypeBiometricsReport, setUnitSelected,
  setParametersDate, setParametersUnitSelected,
  setCurrentTabSetting,
  setShowCreateModalLarvae,
  setShowCreateModalJuvenile,
  setShowCreateModalGrowOut,
  setShowModalMove, setIsLoadingStocking, setIsLoadingLastAnalysis,
  setStageOptions,
} = stockingsSlice.actions;

export const searchStockingsByName = (params: FetchStockingParams) => (dispatch: Function) => {
  dispatch(setStockings({ total: 0, limit: paginationStockings, skip: 0, data: [] }));
  dispatch(fetchStockings(params));
};

const getFieldSelectFetchStockings = (phaseType: string) => {
  const $select = ['name', 'startDate', 'status', 'endDate', 'analysisCount', 'companyId', 'code', 'comment', 'harvestQuantity', 'harvests', 'transfers',
    'larvaePerGram', 'endDate', 'isPublic', 'averageHarvestedWeight', 'bindingStockings', 'campusName', 'moduleName', 'tankName', 'isArchived', 'archivedDate', 'daysToInitialStage'];

  if (phaseType === stockingPhaseTypes.JUVENILE) {
    $select.push('juvenilesNumber');
    $select.push('hectares');
    $select.push('cubicMeters');
    $select.push('startDateJuvenile');
  } else if (phaseType === stockingPhaseTypes.LARVAE) {
    $select.push('litersNumber');
    $select.push('naupliusNumber');
  } else if (phaseType === stockingPhaseTypes.ADULT) {
    $select.push('growOutNumber');
    $select.push('cubicMeters');
    $select.push('hectares');
    $select.push('startDateGrowOut');
  }

  return $select;
};

const getParamsFetchStockings = (props: FetchStockingParams) => {
  const {
    page, companyId, campusId, moduleId, tankId, searchValue, searchType, phaseType, stockingsToShow,
    maturationId, maturationCodeId,
    sortByCampusName = SORT_TYPE.DEFAULT,
    sortByModuleName = SORT_TYPE.DEFAULT,
    sortByTankName = SORT_TYPE.ASC,
    sortByStockingDate = SORT_TYPE.DEFAULT,
  } = props;

  const userSession = getUserSession();

  let skip = 0;
  if (page !== 0) {
    skip = paginationStockings * (page - 1);
  }

  const params = {
    $limit: paginationStockings,
    $skip: skip,
    active: true,
    phaseType,
    $populate: ['campusId', 'moduleId', 'tankId', 'maturationId'],
    sortByCampusName,
    sortByModuleName,
    sortByTankName,
    isArchived: false,
    isPublic: stockingsToShow === stockingsShow.OFFERED ? true : undefined,
    tankId: tankId || undefined,
    moduleId: moduleId || undefined,
    campusId: campusId || undefined,
    maturationId: maturationId || undefined,
    maturationCodeId: maturationCodeId || undefined,
    companyId: hasAdminRole() ? companyId || userSession.companyId : userSession.companyId,
    sortByStartDate: phaseType === stockingPhaseTypes.LARVAE ? sortByStockingDate : undefined,
    sortByStartDateJuvenile: phaseType === stockingPhaseTypes.JUVENILE ? sortByStockingDate : undefined,
    sortByStartDateGrowOut: phaseType === stockingPhaseTypes.ADULT ? sortByStockingDate : undefined,
    'status[$in][0]': stockingsToShow === stockingsShow.ACTIVE ? stockingStatuses.ACTIVE : undefined,
    'status[$in][1]': stockingsToShow === stockingsShow.ACTIVE ? transferTypes.PARTIAL_TRANSFER : undefined,
    searchValue: searchValue && searchType ? searchValue : undefined,
    searchType: searchValue && searchType ? searchType : undefined,
    $select: getFieldSelectFetchStockings(phaseType),
  };

  return params;
};

export const fetchStockings = (props: FetchStockingParams) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  const params = getParamsFetchStockings(props);

  try {
    const response = await axios.get(`${STOCKINGS_URL}-sorts`, { params: params });
    dispatch(setStockings(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStocking = (props: { id: string; token?: string; fetchPresignedUrl?: boolean }) => async (dispatch: Function) => {
  const { id, token, fetchPresignedUrl = true } = props;
  dispatch(setIsLoadingStocking(true));

  const params = {
    '$sort[startDate]': '-1',
    $select: [
      'name',
      'type',
      'code',
      'phaseType',
      'startDate',
      'startDateJuvenile',
      'startDateGrowOut',
      'naupliusNumber',
      'litersNumber',
      'juvenilesNumber',
      'growOutNumber',
      'cubicMeters',
      'hectares',
      'status',
      'maturationId',
      'maturationCode',
      'endDate',
      'tankId',
      'companyId',
      'analysisCount',
      'comment',
      'harvestQuantity',
      'larvaePerGram',
      'voucher',
      'isPublic',
      'averageHarvestedWeight',
      'bindingStockings',
      'isArchived',
      'survivalRate',
      'archivedDate',
      'stockingBindingCode',
      'customStage',
      'poundsHarvested',
      'harvests',
      'transfers',
      'daysToInitialStage',
      'labNames',
      'weight',
    ],
    $populate: ['maturationId', 'campusId', 'tankId', 'moduleId', 'referenceCurveId'],
  };

  let response;

  try {
    if (token) {
      response = await axios.get<Stocking>(`${STOCKINGS_URL}/${id}`, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get<Stocking>(`${STOCKINGS_URL}/${id}`, { params: params });
    }

    if (fetchPresignedUrl && response.data.voucher?.key) {
      const body = {
        'objectKey': response.data.voucher.key,
        'bucketName': bucketName.INVOICE_STOCKING,
        'methodPresignedUrl': methodPresignedUrl.GET,
      };

      const presignedURL = await axios.post(GENERATE_PRESIGNED_URL, body);
      response.data.voucher.presignedUrl = presignedURL.data;
    }

    dispatch(setStocking(response.data));
  } catch (e) {
    dispatch(setStocking(initialState.selectedStocking));
    console.log(e?.response);
  }
  dispatch(setIsLoadingStocking(false));
};

export const fetchCampuses = (props: { companyId?: string; phaseType?: string; type: string }) => async (dispatch: Function) => {
  let campuses: Campus[] = [];
  const { companyId, phaseType, type } = props;
  const userSession = getUserSession();

  const params = new URLSearchParams(
    {
      '$limit': '-1',
      'companyId': companyId || userSession.companyId,
    }
  );

  if (phaseType) {
    params.append('phaseType', phaseType);
  }

  switch (type) {
    case typeFetchCampus.FORM:
      params.append('status', unitStatuses.ACTIVE);
      break;

    case typeFetchCampus.FILTER:
    case typeFetchCampus.MOVE:
      params.append('status[$in][0]', unitStatuses.ACTIVE);
      params.append('status[$in][1]', unitStatuses.INACTIVE);
      break;
  }

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, {
      params: params
    });

    campuses = response.data;
    campuses.sort(sortByName);
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  switch (type) {
    case typeFetchCampus.FORM:
      dispatch(setCampusesForm(campuses));
      break;

    case typeFetchCampus.FILTER:
      dispatch(setCampusesFilter(campuses));
      break;

    case typeFetchCampus.MOVE:
      dispatch(setCampusesMove(campuses));
      break;
  }
};

export const fetchModules = (campusId: string, type: string) => async (dispatch: Function) => {
  let module: Module[] = [];
  const params = new URLSearchParams(
    {
      '$limit': '-1',
      'active': 'true',
    }
  );

  if (campusId) {
    params.append('campusId', campusId);
  }

  try {

    const response = await axios.get<Module[]>(MODULES_URL, {
      params: params
    });

    module = response.data;
    module.sort(sortByName);
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  switch (type) {
    case typeFetchModule.FORM:
      dispatch(setModulesForm(module));
      break;

    case typeFetchModule.FILTER:
      dispatch(setModulesFilter(module));
      break;

    case typeFetchModule.MOVE:
      dispatch(setModulesMove(module));
      break;
  }
};

export const fetchTanks = (campusId?: string, moduleId?: string, type?: string) => async (dispatch: Function) => {
  let tanks: Container[] = [];

  const params = new URLSearchParams(
    {
      '$limit': '-1',
      'active': 'true'
    }
  );

  if (campusId) {
    params.append('campusId', campusId);
  }

  if (moduleId) {
    params.append('moduleId', moduleId);
  }

  try {
    const response = await axios.get<Container[]>(TANKS_URL, {
      params: params
    });

    tanks = response.data;
    tanks.sort(sortByName);
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  switch (type) {
    case typeFetchModule.FORM:
      dispatch(setTanksForm(tanks));
      break;

    case typeFetchModule.FILTER:
      dispatch(setTanksFilter(tanks));
      break;

    case typeFetchModule.MOVE:
      dispatch(setTanksMove(tanks));
      break;

    default:
      dispatch(setTanksFilter(tanks));
      break;
  }
};

export const fetchMaturationsFilter = (companyId?: string) => async (dispatch: Function) => {
  const userSession: UserSession = JSON.parse(
    localStorage.getItem('user') || '{}'
  );

  const params = {
    $limit: -1,
    'active': true,
    companyId: companyId || userSession.companyId,
    '$sort[name]': 1
  };

  try {
    const response = await axios.get<Maturation[]>(MATURATIONS_URL, { params });
    dispatch(setMaturationsFilter(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchGeneticsCodeFilter = (maturationId: string) => async (dispatch: Function) => {
  const params = { $select: ['codes'] };
  const url = `${MATURATIONS_URL}/${maturationId}`;

  try {
    const response = await axios.get<Maturation>(url, { params });
    const maturationCodes = response.data.codes.filter((code) => code.active);
    dispatch(setMaturationCodesFilter(maturationCodes));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchMaturations = (companyId?: string) => async (dispatch: Function) => {
  const userSession: UserSession = JSON.parse(
    localStorage.getItem('user') || '{}'
  );

  const params = {
    $limit: -1,
    active: true,
    companyId: companyId || userSession.companyId,
    '$sort[name]': 1
  };

  try {
    const response = await axios.get<Maturation[]>(MATURATIONS_URL, { params });
    dispatch(setMaturations(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const uploadVoucher = async (props: {
  tankId: string;
  userId: string;
  code: string;
  invoiceImg: string;
}) => {
  const invoiceKey = setImageInvoiceKey(props.tankId, props.userId, props.code);
  const invoiceFile = convertDataURLtoFile(props.invoiceImg, invoiceKey);
  const invoiceUrl = s3BucketURL[ENVIRONMENT].BASE_URL + invoiceKey;

  const body = {
    'objectKey': invoiceKey,
    'bucketName': bucketName.INVOICE_STOCKING,
    'methodPresignedUrl': methodPresignedUrl.POST,
  };

  try {
    const presignedURL = await axios.post<PresignedPostURL>(GENERATE_PRESIGNED_URL, body);
    uploadFileToS3(presignedURL.data, invoiceFile);
  } catch (error) {
    console.error('Error when trying to upload the invoice image');
  }

  return {
    url: invoiceUrl,
    key: invoiceKey,
  };
};

export const createStocking = (props: CreateStockingProps) => async (dispatch: Function) => {
  const { stockingData, paramsToFetchStocking, closeModal } = props;

  dispatch(setIsLoadingCreate(true));

  if (stockingData.invoiceImg) {
    const response = await uploadVoucher({
      tankId: stockingData.tankId,
      userId: stockingData.userId,
      code: stockingData.code,
      invoiceImg: stockingData.invoiceImg
    });

    stockingData.voucher = {
      url: response.url,
      key: response.key,
    };
  }

  try {
    delete stockingData.invoiceImg;
    await axios.post(STOCKINGS_URL, stockingData);
    openSuccessNotification(i18next.t('stockings.created'));
    closeModal();
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingCreate(false));

    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      const days = e.response.data.data.days;
      showErrorStocking({ error, days });
    }
    return;
  }

  dispatch(setIsLoadingCreate(false));
  dispatch(fetchStockings(paramsToFetchStocking));
  dispatch(goToOnboardingNextStep(500));
};

export const createStockings = (props: CreateStockingsProps) => async (dispatch: Function) => {
  const { data, paramsToFetchStocking, closeModal } = props;

  const url = `${STOCKINGS_URL}/batch`;
  dispatch(setIsLoadingCreate(true));

  try {
    await axios.post(url, data);
    openSuccessNotification(i18next.t('stockings.created'));
    closeModal();
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingCreate(false));

    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      const days = e.response.data.data.days;
      showErrorStocking({ error, days });
    }
    return;
  }

  dispatch(setIsLoadingCreate(false));
  dispatch(fetchStockings(paramsToFetchStocking));
  dispatch(goToOnboardingNextStep(500));
};

export const updateReferralGuide = (props: UpdateReferralGuideProps) => async (dispatch: Function) => {
  const { id, stockingData, makeFetchStockings, paramsToFetchStocking } = props;

  dispatch(setIsLoadingEdit(true));

  const response = await uploadVoucher({
    tankId: stockingData.tankId,
    userId: stockingData.userId,
    code: stockingData.code,
    invoiceImg: stockingData.invoiceImg
  });

  const data = {
    voucher: {
      url: response.url,
      key: response.key,
    }
  };

  try {
    await axios.patch(`${STOCKINGS_URL}/${id}`, data);
    openSuccessNotification(i18next.t('stockings.updatedRefferedGuide'));
  } catch (e) {
    console.log(e?.response);
    return;
  }

  dispatch(setIsLoadingEdit(false));
  if (makeFetchStockings) {
    dispatch(fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(fetchStocking({ id }));
  }
};

export const editStocking = (props: EditStockingProps) => async (dispatch: Function) => {
  const { id, stockingData, makeFetchStockings, paramsToFetchStocking } = props;

  dispatch(setHasErrorEdit(true));
  dispatch(setIsLoadingEdit(true));

  try {
    await axios.patch(`${STOCKINGS_URL}/${id}`, stockingData);
    openSuccessNotification(i18next.t('stockings.updated'));

    dispatch(setCurrentTabSetting(stockingCurrentTab.EDIT));
  } catch (e) {
    console.log(e?.response);

    dispatch(setIsLoadingEdit(false));
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      const days = e.response.data.data.days;
      showErrorStocking({ error, days });
    }
    return;
  }

  dispatch(setHasErrorEdit(false));
  dispatch(setIsLoadingEdit(false));
  dispatch(setShowModalEdit(false));
  if (makeFetchStockings) {
    dispatch(fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(fetchStocking({ id }));
  }
};

export const moveStocking = (stockingId: string,
  body: { campusId: string; moduleId: string; tankId: string; tankName: string },
  makeFetchStockings: boolean,
  paramsFetchStocking: { companyId: string; campusId?: string; moduleId?: string; tankId?: string; page: number; phaseType: string; stockingsToShow: string }
) => async (dispatch: Function) => {

  dispatch(setExistErrorToMove(true));
  dispatch(setIsLoadingToMove(true));
  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}/move`, body);
    openSuccessNotification(i18next.t('stockings.moved'));

    dispatch(setCurrentTabSetting(stockingCurrentTab.EDIT));
  } catch (e) {
    dispatch(setIsLoadingToMove(false));
    console.log('ERROR', e);

    if (e.response.data.data && e.response.data.data.tank === tankStatuses.SOWN) {
      openErrorNotification(i18next.t('stockings.tankSown', { name: body.tankName }));
      return;
    }

    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
      return;
    }

    openErrorNotification(i18next.t('stockings.moveStockingError'));
    return;
  }

  dispatch(setIsLoadingToMove(false));
  dispatch(setExistErrorToMove(false));
  dispatch(setShowModalMove(false));
  if (makeFetchStockings) {
    dispatch(fetchStockings(paramsFetchStocking));
  }
};

export const fetchXlsxReportData = (
  data: { companyId: string; fromDate: string; toDate: string; type: string; phaseType: string; typeFile: string; userLang: string; hoursOffset?: number; }
) => async (dispatch: Function) => {
  dispatch(setIsLoadingXlsxReport(true));
  const config = { params: data };

  try {
    const response = await axios.get(XLSX_ANALYSIS_REPORT_URL, config);
    const xlsxData = response.data;
    dispatch(setXlsxReportData(xlsxData));
  } catch (e) {
    console.log('ERROR', e);
  }
  dispatch(setIsLoadingXlsxReport(false));
};

const getHeaderLabels = (columnKeys: string[], phaseType: string) => {
  const headerLabels: GenericParam = {};

  for (let index = 0; index < columnKeys.length; index++) {
    const key = columnKeys[index];
    if (key === 'volume') {
      headerLabels[key] = i18next.t(`parameters.volume.${phaseType}`);
      continue;
    }
    headerLabels[key] = getParameterLabelByKey(key);
  }

  return headerLabels;
};

export const fetchXlsxParametersData = (
  data: { companyId: string; maxDate: string; phaseType: string; unitId: string; }
) => async (dispatch: Function) => {
  dispatch(setIsLoadingXlsxReport(true));
  const config = { params: data };

  try {
    const response = await axios.get<GenericParam>(XLSX_PARAMETERS_REPORT_URL, config);
    dispatch(setIsLoadingXlsxReport(false));

    const resultData = response.data?.resultData || [];
    const columnKeys = response.data?.headerLabels || [];

    if (resultData.length === 0) {
      openErrorNotification(i18next.t('parameters.empty'));
      return;
    }

    const excelData: XlsxExportData = { columnKeys, headerLabels: {}, rowsData: response.data.resultData };
    excelData.headerLabels = getHeaderLabels(columnKeys, data.phaseType);
    const fileName = i18next.t('parameters.file');

    exportToExcel(excelData, fileName);
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchXlsxBiometricsData = (
  data: { companyId: string; date: string; biometricType: string; phaseType: string; campusId: string; weightUnit: string; }
) => async (dispatch: Function) => {
  dispatch(setIsLoadingXlsxReport(true));
  const config = { params: data };

  try {
    const response = await axios.get<BiometricData[]>(XLSX_BIOMETRICS_REPORT_URL, config);
    dispatch(setIsLoadingXlsxReport(false));

    if (response.data.length === 0) {
      openErrorNotification(i18next.t('biometrics.empty'));
      return;
    }

    generateExcel({ biometricData: response.data, biometricType: data.biometricType, phaseType: data.phaseType, weightUnit: data.weightUnit });
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const publishStocking = (props: { stockingId: string; isPublic: boolean; animalsOffered?: number | string; province?: string }) => async (dispatch: Function) => {
  const { stockingId, isPublic, animalsOffered, province } = props;
  const body = { makePublic: isPublic, animalsOffered, province };
  dispatch(setIsLoadingToPublish(true));

  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}/publish`, body);
    dispatch(fetchStocking({ id: stockingId }));

    if (isPublic) {
      openSuccessNotification(i18next.t('stockings.trade.successPublished'));
    } else {
      openSuccessNotification(i18next.t('stockings.trade.successRemoved'));
    }
  } catch (e) {
    if (isPublic) {
      openErrorNotification(i18next.t('stockings.trade.errorPublished'));
    } else {
      openErrorNotification(i18next.t('stockings.trade.errorRemoved'));
    }
  }

  dispatch(setIsLoadingToPublish(false));
};

export const fetchStockingDateRanges = () => async (dispatch: Function) => {
  try {
    const response = await axios.get(STOCKING_DATE_RANGES_URL);
    dispatch(setStockingDateRanges(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

const filterMaturationCodesByStatus = (maturation: Maturation) => {
  if (!maturation.codes) {
    return [];
  }

  return maturation.codes.filter((code) => code.active);
};

export const fetchMaturationCodes = (id: string) => async (dispatch: Function) => {
  const params = {
    $select: ['_id', 'codes'],
  };

  const url = `${MATURATIONS_URL}/${id}`;

  try {
    const response = await axios.get(url, { params });
    const codes = filterMaturationCodesByStatus(response.data);
    dispatch(setMaturationCodes(codes));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const archiveStocking = async (id: string, onSave: Function) => {
  const url = `${STOCKINGS_URL}/${id}/archive`;

  try {
    await axios.patch(url);
    openSuccessNotification(i18next.t('stockings.updated'));
    onSave();
  } catch (e) {
    console.log(e);
    console.log(e?.response);

    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
    }
  }
};

export const fetchStockingByBindingCode = async (props: PropsStockingByBindingCode) => {
  const { code, index, bindingStockings } = props;
  const url = `${STOCKINGS_URL}/${code}/by-binding-code`;

  const itemBindingStocking = bindingStockings[index];

  try {
    const response = await axios.get(url);
    const stocking = response.data;

    itemBindingStocking._id = stocking._id;
    itemBindingStocking.code = stocking.code;
    itemBindingStocking.maturationCode = stocking.maturationCode;
    itemBindingStocking.maturationId = stocking.maturationId;
    itemBindingStocking.name = stocking.name;
    itemBindingStocking.endDate = stocking.endDate;
    itemBindingStocking.stage = stocking.stage;
    itemBindingStocking.campusId = stocking.campusId;
    itemBindingStocking.campusName = stocking.campusName;
    itemBindingStocking.phaseType = stocking.phaseType;
    itemBindingStocking.isValid = true;
    itemBindingStocking.validatedCode = true;
  } catch (e) {
    itemBindingStocking.isValid = false;
    itemBindingStocking.validatedCode = true;
    console.log(e?.response);
  }


  return itemBindingStocking;
};

export const resetStockingFilters = (props: FetchStockingParams) => (dispatch: Function) => {
  dispatch(setCampusSelected(undefined));
  dispatch(setModuleSelected(undefined));
  dispatch(setTankSelected(undefined));
  dispatch(setMaturationSelected(undefined));
  dispatch(setMaturationCodeSelected(undefined));
  dispatch(resetSorts());
  dispatch(setStockingsToShow(stockingsShow.ACTIVE));
  dispatch(setSearchValue(''));
  dispatch(setShowSearchInput(false));
  dispatch(setSearchType(stockingSearchTypes.NAME));

  dispatch(fetchInitialData(props));
};

export const fetchInitialData = (props: FetchStockingParams) => (dispatch: Function) => {
  const unitPhaseType = getUnitPhaseTypeFromStocking(props.phaseType);
  dispatch(fetchStockings(props));
  dispatch(fetchCampuses({ companyId: props.companyId, type: typeFetchCampus.FILTER, phaseType: unitPhaseType }));
  dispatch(fetchMaturationsFilter(props.companyId));
};

export const fetchPreviousStockingByTank = async (tankId: string) => {
  const params = {
    $limit: 1,
    tankId,
    active: true,
    isArchived: false,
    $select: ['name', 'code', 'endDate'],
    'status[$in]': [stockingStatuses.DISCARDED, stockingStatuses.HARVESTED, transferTypes.FULL_TRANSFER],
    '$sort[endDate]': -1,
  };
  let stocking: Stocking | undefined = undefined;
  try {
    const response = await axios.get(STOCKINGS_URL, { params });
    if (response?.data?.data && response.data.data.length > 0) {
      stocking = response.data.data[0];
    }
  } catch (error) {
    console.log('ERROR', error);
  }
  return stocking;
};

export const fetchStageOptions = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<StockingStageOptions>(STOCKING_STAGE_OPTIONS_URL);
    dispatch(setStageOptions(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGeneticCodes = async (maturationId: string) => {
  const params = {
    $select: ['_id', 'codes'],
  };

  const url = `${MATURATIONS_URL}/${maturationId}`;

  const response = await axios.get(url, { params });
  const codes: MaturationCode[] = filterMaturationCodesByStatus(response.data);
  return codes;
};

export const fetchReferenceCurves = (props: { companyId?: string; phaseType: string; parameter: string }) => async (dispatch: Function) => {
  const { companyId, parameter, phaseType } = props;

  const userSession: UserSession = JSON.parse(
    localStorage.getItem('user') || '{}'
  );

  const params = {
    $limit: -1,
    active: true,
    companyId: companyId || userSession.companyId,
    '$sort[name]': 1,
    phaseType,
    type: parameter,
    $select: ['name'],
    'campusId[$exists]': false,
  };

  try {
    const response = await axios.get<ReferenceCurve[]>(REFERENCE_CURVES_URL, { params });
    dispatch(setReferenceCurves(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchLastAnalysisData = (params: { stockingId: string }) => async (dispatch: Function) => {
  const { stockingId } = params;
  dispatch(setIsLoadingLastAnalysis(true));
  try {
    const analysisParams = {
      $limit: 1,
      '$sort[completedDate]': -1,
      'sowingId': stockingId,
      'status': analysisStatuses.COMPLETED,
      '$select': ['code', 'createdAt', 'inputData.stage', 'resultData'],
      isArchived: false,
      'trayId[$exists]': false,
    };

    const analysisResponse = await axios.get(`${ANALYSES_URL}`, { params: analysisParams });
    if (analysisResponse.data?.data?.length > 0) {
      dispatch(setLastAnalysis(analysisResponse.data.data[0]));
      dispatch(setIsLoadingLastAnalysis(false));
      return;
    }
    dispatch(setLastAnalysis(undefined));
  } catch (error) {
    dispatch(setLastAnalysis(undefined));
    console.log('e', error);
  }
  dispatch(setIsLoadingLastAnalysis(false));
};

export default stockingsSlice.reducer;