import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { Stocking } from '../Sowings/interfaces';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { filterOptionSelect } from '../../utils/select';
import { handleResizeEvent } from '../../utils/dimensions';
import { DropdownProps } from '../../common/interfaces/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { useCurrency } from '../../hooks/currency-codes/useCurrency';
import { LrvAlert } from '../../common/components/LrvAlert/LrvAlert';
import DotSpinner from '../../common/components/DotSpinner/DotSpinner';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { LrvSelectV2 } from '../../common/components/LrvSelectV2/LrvSelectV2';
import { calcStages } from '../../common/components/charts/OptimalHarvestPoint/helpers';
import { LrvInputNumberV2 } from '../../common/components/LrvInputNumberV2/LrvInputNumberV2';
import { lineColor, rectColor, selectedTickStoke } from '../../common/components/charts/ShadedPlot/helpers';
import OptimalHarvestPointChartD3 from '../../common/components/charts/OptimalHarvestPoint/OptimalHarvestPointChartD3';
import { calcCurrentDensity, calcDensity, getLabelAxisX, getUnitDensity, getVolume } from '../../helpers/stocking.helpers';
import { CONTAINER_LABEL, DEFAULT_CURRENCY_CODE, stockingPhaseTypes, THEME, volumeUnits, weightUnits, weightUnitsByCompany } from '../../config/commons';

import { CustomHeatMap } from './Calendar/HeatMap';
import { Packers } from './Packers/Packers';
import { MiniCharts } from './MiniCharts/MiniCharts';
import styles from './OptimalHarvestPoint.module.scss';
import { PriceTableForm } from './Packers/PriceTableForm';
import { FeedingStrategy } from './Indicators/interfaces';
import { IndicatorsPanel } from './Indicators/IndicatorsPanel';
import * as feedingTableSlice from './Indicators/feedingTableSlice';
import { OptimalHarvestPointTable } from './OptimalHarvestPointTable';
import * as optimalHarvestPointSlice from './optimalHarvestPointSlice';
import { ChartParameter, CommercialSizeData, OptimalHarvestPointFilters, PocByPacker, ProfitType, ViewMode } from './interfaces';
import { BEST_PACKER_COUNT, calcDataSource, checkValidInputs, DAYS_OF_THE_WEEK, getHeightOfTheOtherElements, getLabelsAxisY, getTopNPocs, getWidthOfTheOtherElements, injectPackerColor, sortPocs } from './helpers';

const { Option } = Select;
let chart: OptimalHarvestPointChartD3 | null = null;

const OptimalHarvestPoint = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;
  const currencyCode = localStorage.getItem('currencyCode') || DEFAULT_CURRENCY_CODE;

  const refChart = useRef<HTMLDivElement | null>(null);
  const refStockingContainer = useRef<HTMLDivElement>(null);
  const refOptions = useRef<HTMLDivElement>(null);
  const refInputsContainer = useRef<HTMLDivElement>(null);
  const refPackerContainer = useRef<HTMLDivElement>(null);
  const refIndicatorContainer = useRef<HTMLDivElement>(null);
  const refMiniChartsContainer = useRef<HTMLDivElement>(null);

  const { company: selectedCompany, phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);
  const {
    firstStage, lastStage, maxStage, lastPopulation, pocPoint, packersWithCommercialSizes,
    dataSource, filters, units, modules, tanks, predictionSelected, allPredictions, isFetchingPackersInfo,
    isFetchingData, isFetchingStocking, isExcluding, harvestsAndTransfers, interestRate,
    colors, harvestOptimalPlan,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const { dailyRation } = useSelector((state: Store) => state.feedingTable);

  const {
    unitId,
    moduleId,
    tankId,
    selectedStocking,
    chartParameter,
    loadCapacity,
    survival,
    mortality,
    weeklyFeeding,
    foodPricePerKg,
    costPerVolumeDay,
    accumulatedCost,
    feedingStrategy,
    costPerHp,
    linearBiomassCoefficientKg,
  } = filters;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [profitType, setProfitType] = useState<ProfitType>(ProfitType.PROFIT_PER_DAY);
  const [chartType, setChartType] = useState(ViewMode.CHART);
  const [initialPopulation, setInitialPopulation] = useState(0);

  const volume = getVolume(selectedStocking);
  const growOutNumber = selectedStocking?.growOutNumber;
  const density = calcDensity(selectedStocking);
  const densityUnit = getUnitDensity(selectedStocking);
  const hideChart = isFetchingData || isFetchingStocking || isFetchingPackersInfo || !selectedStocking?._id || dataSource?.allAnalysis?.length === 0 || packersWithCommercialSizes.length === 0;
  const showSpinner = isFetchingData || isFetchingStocking || isFetchingPackersInfo;
  const isThereInvalidData = !checkValidInputs({ loadCapacity: Number(loadCapacity), survival: Number(survival), mortality: Number(mortality), accumulatedCost: Number(accumulatedCost), costPerVolumeDay: Number(costPerVolumeDay), foodPricePerKg: Number(foodPricePerKg) });

  const { findCurrencyByCode } = useCurrency();
  const currencySymbol = findCurrencyByCode(currencyCode)?.symbol || '';

  const axisXLabelHeight = 24;
  const headerTableHeight = 30;

  const maxTableHeight = height + axisXLabelHeight;

  useEffect(() => {
    dispatch(changeHeader({ title: t('optimalHarvestPoint.title') }));
    optimalHarvestPointSlice.fetchOptimalHarvestPointPing();

    return () => {
      chart = null;
      dispatch(optimalHarvestPointSlice.setDataSource({ allAnalysis: [], excludedAnalyses: [], predictions: [] }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedStocking?.growOutNumber) {
      return;
    }

    if (survival === lastPopulation?.survivalRate) {
      const initialPopulation = lastPopulation?.animalsNumber ?? (selectedStocking.growOutNumber || 0);
      setInitialPopulation(initialPopulation);
      return;
    }
    
    const initialPopulation = Math.round((selectedStocking.growOutNumber * Number(survival) / 100) - harvestsAndTransfers);
    setInitialPopulation(initialPopulation);
  }, [selectedStocking, lastPopulation, harvestsAndTransfers, survival]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements({ inputsContainer: refInputsContainer, refPackerContainer }));
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ refOptions, refStockingContainer, refIndicatorContainer, refMiniChartsContainer }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany?._id) {
      return;
    }
    
    const width = window.innerWidth - getWidthOfTheOtherElements({ inputsContainer: refInputsContainer, refPackerContainer });
    const height = window.innerHeight - getHeightOfTheOtherElements({ refOptions, refStockingContainer, refIndicatorContainer, refMiniChartsContainer });

    setWidth(width);
    setHeight(height);
  }, [selectedCompany?._id, hideChart, pocPoint]);

  useEffect(() => {
    if (!selectedCompany?._id) {
      return;
    }

    switch (phaseTypeSelected) {
      case stockingPhaseTypes.LARVAE:
        dispatch(optimalHarvestPointSlice.setMaxStage(selectedCompany.maxStage));
        break;

      case stockingPhaseTypes.JUVENILE:
        dispatch(optimalHarvestPointSlice.setMaxStage(selectedCompany.maxDayJuvenile));
        break;

      case stockingPhaseTypes.ADULT:
        dispatch(optimalHarvestPointSlice.setMaxStage(selectedCompany.maxDayGrowOut));
        break;
    }

    dispatch(optimalHarvestPointSlice.resetChartFilters({ companyId: selectedCompany._id, phaseType: phaseTypeSelected }));
  }, [selectedCompany, phaseTypeSelected]);

  useEffect(() => {
    if (!dataSource.allAnalysis.length) {
      return;
    }
    
    function updateRange () {
      let firstStage = 0;
      let lastStage = 0;

      const stages = calcStages({ dataSource, maxStage });
      firstStage = stages[0];
      lastStage = stages[1];

      dispatch(optimalHarvestPointSlice.setFirstStage(firstStage));
      dispatch(optimalHarvestPointSlice.setLastStage(lastStage));
    }

    updateRange();
  }, [maxStage, dataSource]);

  useEffect(() => {
    if (dataSource.allAnalysis.length === 0 || !packersWithCommercialSizes || packersWithCommercialSizes.length === 0 || isThereInvalidData || hideChart || !colors.length) {
      return;
    }

    const allAnalysisUpdated = cloneDeep(dataSource.allAnalysis);
    const predictionsUpdated = cloneDeep(dataSource.predictions);

    for (const prediction of predictionsUpdated) {
      const newAverageWeight = prediction.resultData.averageWeight * feedingStrategy;
      prediction.resultData.averageWeight = newAverageWeight;
    }

    const chartDataSource = { ...dataSource, predictions: predictionsUpdated, allAnalysis: allAnalysisUpdated };
    const animalsSown = selectedStocking?.growOutNumber || 0;
    const dailyFeeding = weeklyFeeding ? Number(weeklyFeeding) / DAYS_OF_THE_WEEK : undefined;

    const { dataSourceByStage, allPredictions, pocByPackers, allPoints } = calcDataSource({
      dataSource: chartDataSource, accumulatedCost: Number(accumulatedCost), animalsSown, costPerVolumeDay: Number(costPerVolumeDay), dailyFeeding, survival: Number(survival),
      foodPricePerKg: Number(foodPricePerKg), harvestsAndTransfers, initialPopulation, mortality: Number(mortality), volume, packersWithCommercialSizes, interestRate,
      profitType, dailyRation, linearBiomassCoefficient: linearBiomassCoefficientKg, costPerHp,
    });
    
    dispatch(optimalHarvestPointSlice.setAllPoints(allPoints));
    dispatch(optimalHarvestPointSlice.setAllPredictions(allPredictions));
    dispatch(optimalHarvestPointSlice.setDataSourceByStage(dataSourceByStage));
    
    const allPackers: PocByPacker[] = sortPocs(pocByPackers);
    let bestPackers: PocByPacker[] = getTopNPocs(allPackers, BEST_PACKER_COUNT);
    bestPackers = injectPackerColor({ bestPackers, colors });

    dispatch(optimalHarvestPointSlice.setAllPackers(allPackers));
    dispatch(optimalHarvestPointSlice.setBestPackers(bestPackers));

    const predictionPoint = allPredictions.find(item => item.x === bestPackers[0].x);
    let pocPoint: CommercialSizeData | undefined = undefined;

    if (predictionPoint) {
      pocPoint = predictionPoint.commercialSizeData.find(item => item.packerId === bestPackers[0].packerId);
      dispatch(optimalHarvestPointSlice.setPocPoint(pocPoint));
    }

    let parameter = chartParameter;
    if (chartParameter === ChartParameter.BIOMASS) {
      parameter = selectedCompany.weightUnit === weightUnitsByCompany.POUND ? ChartParameter.BIOMASS_LB : ChartParameter.BIOMASS_KG;
    }

    if (chart) {
      chart.refreshChart({
        allPredictions,
        companyData: selectedCompany,
        pocPoint,
        bestPackers,
        chartParameter,
        initialPopulation,
        firstStage,
        lastStage,
        width,
        height,
        dispatch,
        selectedTickStroke: tickStroke,
        currencySymbol,
        profitType,
        showTriangle: chartType === ViewMode.CHART,
      });
      return;
    }

    chart = new OptimalHarvestPointChartD3({
      companyData: selectedCompany,
      container: refChart.current,
      bestPackers,
      firstStage,
      height,
      lastStage,
      width,
      selectedTickStroke: tickStroke,
      dispatch,
      chartParameter: parameter,
      initialPopulation,
      allPredictions,
      currencySymbol,
      pocPoint,
      profitType,
      showTriangle: chartType === ViewMode.CHART,
    });
  }, [
    dispatch, feedingStrategy, weeklyFeeding, costPerVolumeDay, refInputsContainer, initialPopulation, harvestsAndTransfers, volume,
    chartParameter, isExcluding, survival, mortality, selectedStocking, selectedCompany, dataSource, firstStage, lastStage, width, height,
    colorFillRect, colorLine, tickStroke, accumulatedCost, foodPricePerKg, currencySymbol, hideChart,
    packersWithCommercialSizes, isThereInvalidData, profitType, interestRate, chartType,
    colors, dailyRation, linearBiomassCoefficientKg, costPerHp,
  ]);

  useEffect(() => {
    if (!predictionSelected?.x) {
      return;
    }

    const stage = predictionSelected.x;
    const predictionUpdated = allPredictions.find(item => item.x === stage);
    const newPredictionSelected = predictionUpdated?.commercialSizeData.find(item => item.packerId === predictionSelected.packerId);
    dispatch(optimalHarvestPointSlice.setPredictionSelected(newPredictionSelected));
  }, [allPredictions]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    if (chart instanceof OptimalHarvestPointChartD3) {
      chart.refreshPoints({ predictionSelected });
    }
  }, [predictionSelected]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelectV2
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unitId || undefined}
        size='small'
        containerClassName={styles.inputContainer}
        onChange={(value) => {
          dispatch(optimalHarvestPointSlice.setTanks([]));
          dispatch(optimalHarvestPointSlice.setSelectedTank(''));
          dispatch(optimalHarvestPointSlice.setSelectedModule(''));
          dispatch(optimalHarvestPointSlice.setSelectedStocking({ _id: '', name: '' } as Stocking));
          dispatch(feedingTableSlice.setTankPocParameter({ companyId: '', campusId: '', tankId: '', dataset: [], costPerVolumeDay: 0, loadCapacity: 0, feedingStrategy: FeedingStrategy.NORMAL }));
          chart = null;

          dispatch(optimalHarvestPointSlice.fetchHarvestOptimalPlans({ campusId: value, companyId: selectedCompany._id }));
          dispatch(optimalHarvestPointSlice.setSelectedUnit(value));
          dispatch(optimalHarvestPointSlice.fetchModules(value, phaseTypeSelected));
          dispatch(optimalHarvestPointSlice.fetchCampusPocParameter({ campusId: value, companyId: selectedCompany._id }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('campus.campus')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
        disabled={phaseTypeSelected !== stockingPhaseTypes.ADULT}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelectV2>
    );
  };
  
  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelectV2
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        size='small'
        containerClassName={styles.inputContainer}
        onChange={(value) => {
          const newModuleId = value;
          dispatch(optimalHarvestPointSlice.setTanks([]));
          dispatch(optimalHarvestPointSlice.setSelectedTank(''));
          dispatch(optimalHarvestPointSlice.setSelectedStocking({ _id: '', name: '' } as Stocking));
          chart = null;

          dispatch(optimalHarvestPointSlice.setSelectedModule(newModuleId));
          dispatch(optimalHarvestPointSlice.fetchTanks(unitId, newModuleId));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('campus.module')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
        disabled={!unitId}
      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelectV2>
    );
  };

  const renderContainersDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelectV2
        id='dropdown_tanks'
        theme={theme}
        className={className}
        value={tankId || undefined}
        size='small'
        onChange={(containerId) => {
          dispatch(optimalHarvestPointSlice.fetchActiveStocking({ campusId: unitId, moduleId, containerId, companyId: selectedCompany._id, harvestOptimalPlans: harvestOptimalPlan.harvestOptimalPlans }));
          dispatch(optimalHarvestPointSlice.fetchTankPocParameter({ tankId: containerId, companyId: selectedCompany._id }));
          
          dispatch(optimalHarvestPointSlice.setIsExcluding(false));
          dispatch(optimalHarvestPointSlice.setAnalysesToExclude([]));
          dispatch(optimalHarvestPointSlice.setSelectedTank(containerId));
          dispatch(optimalHarvestPointSlice.setPredictionSelected(undefined));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
        disabled={!moduleId}
      >
        {tanks.map((tank) => <Option key={tank._id} value={tank._id}>{tank.name}</Option>)}
      </LrvSelectV2>
    );
  };

  const renderEmptyScreens = () => {
    if (showSpinner) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }
  };

  const renderEmptyContainer = (props: {message: string}) => {
    const { message } = props;

    return (
      <div className={cx(styles.alertContainer, isLightTheme ? styles.lightContainer : styles.darkContainer)}>
        <LrvAlert
          theme={theme}
          className={styles.alert}
          message={message}
          type='warning'
          showIcon
        />
      </div>
    );
  };

  const renderEmptySizesChartColumn = () => {
    if (showSpinner) {
      return null;
    }

    if (phaseTypeSelected !== stockingPhaseTypes.ADULT) {
      return renderEmptyContainer({ message: t('optimalHarvestPoint.chartDisabled') });
    }

    if (!isFetchingPackersInfo && packersWithCommercialSizes.length === 0) {
      return renderEmptyContainer({ message: t('optimalHarvestPoint.emptyCommercialSizePriceTable') });
    }

    if ((hideChart && !tankId) || isThereInvalidData) {
      return renderEmptyContainer({ message: t('optimalHarvestPoint.invalidInputsData') });
    }

    if (dataSource?.allAnalysis.length === 0 && selectedStocking._id) {
      return renderEmptyContainer({ message: t('optimalHarvestPoint.noEnoughAnalyses') });
    }

    if (!selectedStocking?._id) {
      return renderEmptyContainer({ message: t('shadedplot.emptyStocking') });
    }
  };

  const renderLabelAxisX = () => {
    if (chartType === ViewMode.TABLE) {
      return;
    }
    
    return (
      <div
        className={
          cx(
            styles.labelAxisX,
            isLightTheme ? styles.axisLight : styles.axisDark,
            (hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart,
          )
        }
      >
        {getLabelAxisX(phaseTypeSelected)}
      </div>
    );
  };

  const renderTable = () => {
    if (chartType === ViewMode.CHART) {
      return;
    }

    return (
      <div
        className={
          cx(
            styles.table,
            (hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart,
          )
        }
        style={{
          height: maxTableHeight,
        }}
      >
        <OptimalHarvestPointTable
          height={height - headerTableHeight}
          profitType={profitType}
          theme={theme}
          currencySymbol={currencySymbol}
        />
      </div>
    );
  };

  const renderText = (label: string) => {
    return (
      <LrvText
        className={isLightTheme ? styles.lightText : styles.darkText}
        theme={theme}
        text={label}
      />
    );
  };

  const renderLabelsAxisY = () => {
    const label = getLabelsAxisY({ chartParameter, weightUnit: selectedCompany.weightUnit, currencySymbol, profitType });

    return (
      <div
        className={cx(styles.chartHeader, (hideChart || isThereInvalidData || chartType === ViewMode.TABLE) ? styles.hideChart : styles.showChart)}
      >
        <div className={styles.labelAxisYLeft}>
          {renderText(label)}
        </div>
      </div>
    );
  };

  const renderValueOptions = () => {
    return (
      <Space className={styles.actions}>
        <Radio.Group
          className={styles.valueType}
          value={profitType}
          onChange={(event) => setProfitType(event.target.value)}
          size='small'
        >
          <Radio.Button
            className={cx(styles.leftOption, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, profitType === ProfitType.PROFIT_PER_DAY ? styles.radioSelected : '')}
            value={ProfitType.PROFIT_PER_DAY}
          >
            <div>{t('optimalHarvestPoint.profitPerDay')}</div>
          </Radio.Button>

          <Radio.Button
            className={cx(styles.rightOption, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, profitType === ProfitType.PROFIT_TOTAL ? styles.radioSelected : '')}
            value={ProfitType.PROFIT_TOTAL}
          >
            <div>{t('optimalHarvestPoint.profit')}</div>
          </Radio.Button>
        </Radio.Group>

        <Radio.Group
          className={styles.valueType}
          value={chartType}
          onChange={(event) => setChartType(event.target.value)}
          size='small'
        >
          <Radio.Button
            className={cx(styles.leftOption, styles.radioButtonIcon, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, chartType === ViewMode.CHART ? styles.radioSelected : '')}
            value={ViewMode.CHART}
          >
            <Icon name='line-chart' theme={theme} />
          </Radio.Button>

          <Radio.Button
            className={cx(styles.rightOption, styles.radioButtonIcon, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, chartType === ViewMode.TABLE ? styles.radioSelected : '')}
            value={ViewMode.TABLE}
          >
            <Icon name='table-2' theme={theme} type='none' />
          </Radio.Button>
        </Radio.Group>
      </Space>
    );
  };

  const renderCostPerVolumeDayInput = () => {
    return (
      <LrvInputNumberV2
        theme={theme}
        containerClassName={styles.inputContainer}
        value={costPerVolumeDay}
        size='small'
        addonAfter={currencySymbol}
        onChange={(value) => {
          const filterParams: OptimalHarvestPointFilters = {
            ...filters,
            costPerVolumeDay: Number(value),
          };

          dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
        }}
        title={`${t('optimalHarvestPoint.costPerVolumeDay')}`}
        parser={(input) => `${input}`.replace(',', '.')}
      />
    );
  };

  const renderInputsContainer = () => {
    return (
      <div
        className={styles.inputsContainer}
      >
        <Space direction='vertical' size='middle'>
          {renderUnitsDropdown({ theme })}
          {renderModulesDropdown({ theme })}
          {renderContainersDropdown({ theme })}

          <LrvInputNumberV2
            theme={theme}
            containerClassName={styles.inputContainer}
            value={foodPricePerKg}
            size='small'
            addonAfter={currencySymbol}
            title={t('optimalHarvestPoint.foodPricePerKg')}
            onChange={(value) => {
              const filterParams: OptimalHarvestPointFilters = {
                ...filters,
                foodPricePerKg: Number(value),
              };
    
              dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
            }}
            parser={(input) => `${input}`.replace(',', '.')}
          />

          <LrvInputNumberV2
            min={0}
            theme={theme}
            value={loadCapacity}
            size='small'
            addonAfter={`${weightUnits.KG}/${volumeUnits.HA}`}
            containerClassName={styles.inputContainer}
            title={`${t('optimalHarvestPoint.loadCapacity')}`}
            onChange={(value) => {
              const filterParams: OptimalHarvestPointFilters = {
                ...filters,
                loadCapacity: Number(value),
              };
    
              dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
            }}
            parser={(input) => `${input}`.replace(',', '.')}
          />

          {renderCostPerVolumeDayInput()}

          <LrvInputNumberV2
            min={0}
            max={100}
            theme={theme}
            value={survival}
            size='small'
            addonAfter='%'
            containerClassName={styles.inputContainer}
            title={`${t('stockings.populations.survival')}`}
            onChange={(value) => {
              const filterParams: OptimalHarvestPointFilters = {
                ...filters,
                survival: Number(value)
              };
            
              dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
            }}
            parser={(input) => `${input}`.replace(',', '.')}
          />

          <LrvInputNumberV2
            min={0}
            theme={theme}
            value={mortality}
            size='small'
            addonAfter='%'
            containerClassName={styles.inputContainer}
            title={`${t('optimalHarvestPoint.weeklyMortality')}`}
            onChange={(value) => {
              const filterParams: OptimalHarvestPointFilters = {
                ...filters,
                mortality: Number(value)
              };
            
              dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
            }}
            parser={(input) => `${input}`.replace(',', '.')}
          />

          <LrvInputNumberV2
            theme={theme}
            containerClassName={styles.inputContainer}
            value={accumulatedCost}
            size='small'
            addonAfter={currencySymbol}
            title={t('optimalHarvestPoint.directCosts')}
            onChange={(value) => {
              const filterParams: OptimalHarvestPointFilters = {
                ...filters,
                accumulatedCost: Number(value)
              };
            
              dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
            }}
            parser={(input) => `${input}`.replace(',', '.')}
          />

        </Space>
      </div>
    );
  };

  const renderStockingSelected = () => {
    const stockingName = selectedStocking?.name;
    const stockingDensity = `${density} ${densityUnit}`;

    return (
      <Space className={styles.stocking}>
        <LrvText className={styles.name} theme={theme} text={stockingName} />

        <LrvTooltip
          title={() => (
            <div className={styles.info}>
              <div>{t('stockings.sownAnimals')}: <strong>{growOutNumber}</strong></div>
              <div>{t('stockings.populations.population')}: <strong>{initialPopulation}</strong></div>
              <div>{t('stockings.hectares')}: <strong>{volume}</strong></div>
              <div>{t('production.stockingInfoModal.stockingDensity')}: <strong>{stockingDensity}</strong></div>
              <div>{t('production.stockingInfoModal.currentDensity')}: <strong>{calcCurrentDensity({ population: initialPopulation, volume })} {densityUnit}</strong></div>
            </div>
          )}
        >
          <div>
            <Icon
              name='information'
              type='fill'
              className={styles.icon}
              theme={theme}
            />
          </div>
        </LrvTooltip>
      </Space>
    );
  };

  return (
    <div className={styles.optimalHarvestPoint}>
      <div className={cx(styles.body, isLightTheme ? styles.bodyLight : styles.bodyDark)}>
        {renderEmptyScreens()}
        <div
          ref={refInputsContainer}
          className={styles.inputsColumn}
        >
          {renderInputsContainer()}
        </div>
        
        <div className={styles.chart}>
          <div
            ref={refStockingContainer}
            className={cx(styles.stockingContainer, (hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart)}
          >
            {renderStockingSelected()}
          </div>

          <div className={styles.chartContainer}>
            <div className={styles.chartColumn}>
              {renderEmptySizesChartColumn()}

              <div className={cx(styles.rowBody, styles.predictionsChart)}>
                <div className={styles.container}>
                  <div
                    ref={refOptions}
                    className={cx(styles.options, (hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart)}
                  >
                    {renderLabelsAxisY()}
                    {renderValueOptions()}
                  </div>

                  <div
                    ref={refChart}
                    className={
                      cx(
                        styles.chart,
                        (hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart,
                      )
                    }
                    style={{
                      height: chartType === ViewMode.TABLE ? 0 : 'auto',
                    }}
                  />
                  {renderLabelAxisX()}
                  {renderTable()}
                </div>
              </div>

              <div
                ref={refIndicatorContainer}
                className={(hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart}
              >
                <IndicatorsPanel />
              </div>

              <div
                ref={refMiniChartsContainer}
                className={(hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart}
              >
                <MiniCharts showChart={!(hideChart || isThereInvalidData)}/>
              </div>
            </div>

            <div
              ref={refPackerContainer}
              className={(hideChart || isThereInvalidData) ? styles.hideChart : styles.showChart}
            >
              <Packers
                height={(hideChart || isThereInvalidData) ? 0 : maxTableHeight}
                theme={theme}
              />
              <CustomHeatMap />
              
            </div>
          </div>
        </div>
      </div>

      <PriceTableForm theme='light'/>
    </div>
  );
};

export default OptimalHarvestPoint;